var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xw-full",attrs:{"flat":"","min-height":"400"}},[_c('v-card-title',{staticClass:"xflex xflex-nowrap xflex-row xjustify-between xitems-center"},[_c('span',{staticClass:"xfont-semibold md:xtext-[17px] xtext-[15px] xtext-gray-700"},[_vm._v(" Upcoming Invoice ")])]),_c('app-table',{attrs:{"empty-text":"No upcoming invoice yet.","loading":_vm.upcoming_loading,"headings":_vm.headers1,"items":_vm.upcomings},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secondsToDate")(item.created))+" ")])]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dollar")(item.total))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("ucwords")(item.status))+" ")])]}},{key:"receipt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.paid ? "Paid" : "Unpaid")+" ")])]}}])}),_c('v-divider',{staticClass:"xmy-3"}),_c('v-card-title',{staticClass:"xflex xflex-nowrap xflex-row xjustify-between xitems-center"},[_c('span',{staticClass:"xfont-semibold md:xtext-[17px] xtext-[15px] xtext-gray-700"},[_vm._v(" Billing History ")])]),_c('app-table',{attrs:{"loading":_vm.history_loading,"headings":_vm.headers2,"items":_vm.histories,"empty-text":"No invoice yet."},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secondsToDate")(item.created))+" ")])]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dollar")(item.total))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("ucwords")(item.status))+" ")])]}},{key:"receipt",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.goAway(item.hosted_invoice_url)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Info ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.goAway(item.invoice_pdf)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Invoice ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }