<template>
  <v-card flat min-height="400" class="xw-full">
    <v-card-title
      class="xflex xflex-nowrap xflex-row xjustify-between xitems-center"
    >
      <span class="xfont-semibold md:xtext-[17px] xtext-[15px] xtext-gray-700">
        Upcoming Invoice
      </span>
    </v-card-title>
    <app-table
      empty-text="No upcoming invoice yet."
      :loading="upcoming_loading"
      :headings="headers1"
      :items="upcomings"
    >
      <template v-slot:created="{ item }">
        <span> {{ item.created | secondsToDate }} </span>
      </template>
      <template v-slot:total="{ item }">
        <span> {{ item.total | dollar }} </span>
      </template>
      <template v-slot:status="{ item }">
        <span> {{ item.status | ucwords }} </span>
      </template>
      <template v-slot:receipt="{ item }">
        <span> {{ item.paid ? "Paid" : "Unpaid" }} </span>
      </template>
    </app-table>
    <!-- <pre> {{ upcomings }} </pre> -->
    <v-divider class="xmy-3"></v-divider>
    <v-card-title
      class="xflex xflex-nowrap xflex-row xjustify-between xitems-center"
    >
      <span class="xfont-semibold md:xtext-[17px] xtext-[15px] xtext-gray-700">
        Billing History
      </span>
    </v-card-title>
    <app-table
      :loading="history_loading"
      :headings="headers2"
      :items="histories"
      empty-text="No invoice yet."
    >
      <template v-slot:created="{ item }">
        <span> {{ item.created | secondsToDate }} </span>
      </template>
      <template v-slot:total="{ item }">
        <span> {{ item.total | dollar }} </span>
      </template>
      <template v-slot:status="{ item }">
        <span> {{ item.status | ucwords }} </span>
      </template>
      <template v-slot:receipt="{ item }">
        <icon-list icon="mdi-dots-horizontal">
          <v-list dense>
            <v-list-item @click="goAway(item.hosted_invoice_url)">
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left> mdi-eye-circle-outline </v-icon>
                View Info
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="goAway(item.invoice_pdf)">
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left> mdi-eye-circle-outline </v-icon>
                View Invoice
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </icon-list>
      </template>
    </app-table>
    <!-- <pre>{{ histories }} </pre> -->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      histories: [],
      upcomings: [],
      headers1: [
        { text: "Invoice Date", value: "created", sortable: true },
        { text: "Amount", value: "total", sortable: true },
        { text: "Status", value: "status", sortable: true },
        {
          text: "Paid",
          value: "receipt",
          sortable: false,
          align: "center",
          tdClass: "xtext-center",
        },
      ],
      headers2: [
        { text: "Invoice Date", value: "created", sortable: true },
        { text: "Amount", value: "total", sortable: true },
        { text: "Status", value: "status", sortable: true },
        {
          text: "Info",
          value: "receipt",
          sortable: false,
          align: "center",
          tdClass: "xtext-center",
        },
      ],
      upcoming_loading: false,
      history_loading: false,
    };
  },
  filters: {
    secondsToDate(val) {
      return moment.unix(val).format("LL");
    },
    dollar(val) {
      return `$${(val / 100).toFixed(2)}`;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.fetchHistory();
    this.fetchUpcoming();
  },
  methods: {
    goAway(link) {
      window.open(link, "_blank");
    },
    fetchHistory() {
      this.history_loading = true;
      this.$axios
        .get(`api/subscription/history/${this.user.team_id}`)
        .then(({ data }) => {
          this.histories = data;
        })
        .finally(() => (this.history_loading = false));
    },
    fetchUpcoming() {
      this.upcoming_loading = true;
      this.$axios
        .get(`api/subscription/upcoming-invoices/${this.user.team_id}`)
        .then(({ data }) => {
          this.upcomings = data;
        })
        .finally(() => (this.upcoming_loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
